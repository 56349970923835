<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { service } from "./utils/request";
import { Dialog } from "vant";
export default {
  name: "App",
  data() {
    return {
      loading: false,
      phoneReg: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      pwdReg: /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{8,20}$/,
      form: {
        loginName: "",
      },
      verifyText: "获取验证码",
      canGetVerify: true,
      pwdView: false,
      repwdView: false,
    };
  },
  mounted() {
    this.userPid = this.getQueryString("userPid") || "";
  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return null;
    },

    async getVerify() {
      if (!this.form.loginName || !this.phoneReg.test(this.form.loginName))
        return alert("请输入正确的手机号");
      if (!this.canGetVerify) return;
      this.loading = true;
      const res = await service.postForm("/app/register/check-login-name", {
        loginName: this.form.loginName,
      });
      if (res.data.code === "00000") {
        if (res.data.data) {
          const _res = await service.postForm("/app/register/send-sms-code", {
            loginName: this.form.loginName,
          });
          if (_res.data.code === "00000") {
            this.loading = false;
            this.canGetVerify = false;
            let interval = null;
            if (interval) return;
            let num = 59;
            this.verifyText = `重新获取（${num}s）`;
            interval = setInterval(() => {
              num--;
              this.verifyText = `重新获取（${num}s）`;
              if (num <= 0) {
                clearInterval(interval);
                this.canGetVerify = true;
                this.verifyText = "获取验证码";
                this.interval = null;
              }
            }, 1000);
          } else {
            this.loading = false;
            Dialog({ message: _res.data.msg || "网络错误，请重试" });
          }
        } else {
          this.loading = false;
          Dialog({ message: "该用户已注册" });
        }
      } else {
        this.loading = false;
        Dialog({ message: res.data.msg || "网络错误，请重试" });
      }
    },
    async register() {
      if (!this.form.loginName) {
        return Dialog({ message: "请输入手机号" });
      }
      if (!this.phoneReg.test(this.form.loginName)) {
        return Dialog({ message: "请输入正确的手机号" });
      }
      if (!this.form.smsCheckCode) {
        return Dialog({ message: "请输入验证码" });
      }
      if (!this.form.loginPwd) {
        return Dialog({ message: "请输入密码" });
      }
      if (!this.pwdReg.test(this.form.loginPwd)) {
        return Dialog({ message: "密码格式有误" });
      }
      if (!this.form.reLoginPwd) {
        return Dialog({ message: "请在此输入密码" });
      }
      if (this.form.loginPwd !== this.form.reLoginPwd) {
        return Dialog({ message: "两次密码输入不一致" });
      }
      const res = await service.post("/app/register", {
        ...this.form,
        userSource: 0,
        userPid: this.userPid,
      });
      if (res.data.code === "00000") {
        Dialog({ message: "注册成功！赶快下载app登录吧～" });
      } else {
        Dialog({ message: res.data.msg || "网络错误，请重试" });
      }
    },
  },
};
</script>
